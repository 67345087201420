<template>
  <div class="salarySiginPassword">
    <van-nav-bar :title="$t('salarySiginList.sslTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem;"></div>
    <div class="content">
      <van-search @search="onSearch"
                  v-model="value"
                  :placeholder="$t('salarySiginList.sslTxt2')" />
      <ul class="content_ul">
        <li @click="visitMonth(value,key)"
            v-for="(value,key) in data"
            :key="key">
          <div class="line"></div>
          <div class="year_bg">{{ value.pay_month_title }}</div>
          <div class="content_des">
            {{$t('salarySiginList.sslTxt3')}}￥{{ value.totalmoney }}
          </div>
          <div class="svg_sigin"
               style="display:none;"
               v-show="value.is_wxsign">
            <svg-icon icon-class="zu820"></svg-icon>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
import { getEmpMonthSalaryList, getEmpMonthSalaryMaster } from '@api/wx.js'
import { Dialog, Toast } from 'vant'
export default {
  data () {
    let firstchangepwd = this.$route.query.firstchangepwd;
    let isfirstlogin = this.$route.query.isfirstlogin;
    let nosign = this.$route.query.nosign;
    let use_pay_feedback = this.$route.query.use_pay_feedback
    return {
      firstchangepwd,
      isfirstlogin,
      nosign,
      use_pay_feedback,
      userInfo,
      value: "",
      data: []
    };
  },
  watch: {

  },
  created () {
    this.getData();
  },
  methods: {
    onClickLeft () {
      this.$router.push("/salarySiginPassword");
    },
    onClickRight () {

    },
    onSearch () {
    },
    visitMonth (value, key) {
      getEmpMonthSalaryMaster({
        empid: this.userInfo.empid,
        yymm: value.yymm
      }).then(res => {
        let data = res.data;
        if (data.length == 1)        {
          this.$router.push({ path: "/salaryDetails", query: { use_pay_feedback: this.use_pay_feedback, isOnlyOne: true, autoid: data[0].autoid, yymm: value.yymm, china: value.china, num: '', firstchangepwd: this.firstchangepwd, isfirstlogin: this.isfirstlogin, nosign: this.nosign } });
        } else        {
          this.$router.push({ path: "/salaryMonth", query: { yymm: value.yymm, china: value.china, firstchangepwd: this.firstchangepwd, isfirstlogin: this.isfirstlogin, nosign: this.nosign, use_pay_feedback: this.use_pay_feedback } });
        }
      })
    },
    getData () {
      getEmpMonthSalaryList({
        empid: this.userInfo.empid,
        yymm: ""
      }).then(res => {
        console.log(res)
        let data = res.data;
        for (let i = 0; i < data.length; i++)
        {
          // data[i].china = data[i].yymm.slice(0, 4) + '年' + data[i].yymm.slice(4) + '月'
          data[i].china = data[i].pay_month_title
        }
        this.data = data;
      })
    }
  },
};
</script>
<style lang="less" scoped>
.salarySiginPassword {
  height: 100%;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 92px);
    overflow: auto;
    .content_ul {
      width: 100%;
      li {
        margin-top: 25px;
        background: #fff;
        position: relative;
      }
      .line {
        width: 100%;
        height: 18px;
        background: #2b8df0;
        border-bottom-right-radius: 12px;
      }
      .year_bg {
        height: 56px;
        background: #2b8df0;
        // width: 50%;
        width: fit-content;
        padding: 0 10px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        text-align: center;
        line-height: 56px;
        color: #fff;
        font-size: 32px;
      }
      .content_des {
        width: 100%;
        height: 115px;
        line-height: 115px;
        padding-left: 35px;
        font-size: 32px;
        font-weight: bold;
      }
      .svg_sigin {
        position: absolute;
        width: 107px;
        height: 107px;
        right: 30px;
        top: 50%;
        margin-top: -44.5px;
        z-index: 999;
        .svg-icon {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>